import React from 'react';
import Avatar from './avatar';

const Hero = () => (
	<div className="bg-dark-blue mb-10">
		<div className="container mx-auto pt-6 pb-10">
			<div className="flex flex-wrap max-w-md px-6 py-16 mx-auto sm:max-w-xl md:max-w-4xl lg:max-w-5xl md:py-24 lg:py-32">
				<div className="flex items-center justify-center w-full md:w-1/3">
					<Avatar />
				</div>
				<div className="flex items-center mt-6 md:w-2/3 md:mt-0 md:pl-3 lg:pl-6">
					<div className="flex flex-col items-center text-primary dark:text-secondary md:items-start">
						<span className="text-xl text-white">Hi, my name is</span>
						<h1 className="text-neon-pink text-4xl md:text-6xl font-semibold tracking-tighter">Solomon Scott</h1>
						<p className="text-xl text-white text-center md:text-left">I'm a Full Stack Developer based in Washington D.C. where I specialize in building custom and user friendly applications. I'm also a trapeze artist, tennis player, traveler, comic book fanatic, and movie geek.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default Hero;
