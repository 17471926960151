import React from 'react';
import Header from './header';
import Hero from './hero';
import Footer from './footer';

import '../fonts/LineIcons.css';
import '../fonts/fonts.css';
import './styles.css';

const Layout = ({ children }) => (
	<>
		<Header />
		<div role="main">
			<Hero />
			<div className="container mx-auto">
				{children}
			</div>
		</div>
		<Footer />
	</>
);

export default Layout;
