import React from 'react';

const Footer = () => (
	<div role="contentinfo" className="bg-dark-blue font-mono">
		<div className="container mx-auto py-6">
			<div className="flex flex-col md:flex-row justify-center md:justify-between px-4 md:px-0">
				<span className="text-white text-lg text-center md:text-left">{new Date().getFullYear()} &copy; Solomon Scott</span>
				<ul className="flex flex-row text-white justify-center md:justify-start">
					<li>
						<a className="social-icon" href="https://www.facebook.com/solomon.p.scott">
							<i className="lni lni-facebook-filled"></i>
							<span className="sr-only">Facebook</span>
						</a>
					</li>
					<li>
						<a className="social-icon" href="https://twitter.com/SolomonSScott">
							<i className="lni lni-twitter-filled"></i>
							<span className="sr-only">Twitter Profile</span>
						</a>
					</li>
					<li>
						<a className="social-icon" href="https://www.instagram.com/putonsumsox/">
							<i className="lni lni-instagram-original"></i>
							<span className="sr-only">Instagram</span>
						</a>
					</li>
					<li>
						<a className="social-icon" href="https://github.com/SolomonSScott">
							<i className="lni lni-github-original"></i>
							<span className="sr-only">Github Profile</span>
						</a>
					</li>
					<li>
						<a className="social-icon" href="https://www.linkedin.com/in/solomon-scott-08b6852b/">
							<i className="lni lni-linkedin-original"></i>
							<span className="sr-only">LinkedIn</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
);

export default Footer;
