import React, { useContext } from 'react';
import { Link } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';

import ModalContext from '../context/modal';

const Header = () => {
	const { open, toggle } = useContext(ModalContext);
	return (
		<header role="banner" className="bg-dark-blue">
			<div className="flex flex-col md:flex-row items-center md:items-stretch justify-between content-center">
				<div className="p-3">
					<Link to="/">
						<img className="h-10" src="images/sss-logo.png" alt="Solomon Scott's Logo" title="Solomon Scott's Logo"/>
						<span className="sr-only">Solomon Scott</span>
					</Link>
				</div>

				<nav role="navigation" className="md:p-3">
					<ul className="flex flex-row content-center text-white font-mono text-lg">
						<li className="nav-link"><button className="appearance-none" onClick={() => scrollTo('#about')}>About</button></li>
						<li className="nav-link"><button className="appearance-none" onClick={() => scrollTo('#posts')}>Posts</button></li>
						<li className="nav-link"><button className="appearance-none" onClick={() => toggle(!open)}>Contact</button></li>
					</ul>
				</nav>
			</div>
		</header>
	);
}

export default Header;
